.hero {
  background-image: url("../../../../public/images/bg.webp");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: black;
}


.hero #heading h3 {
  color: black;
}

.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 768px) {
  .margin {
    height: 60vh;
  }
  .hero {
    background-position: center;
    padding-top: 50%;
    height: 80vh;
  }
  .hero {
    font-weight:bolder
  }
  .hero .row {
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1324px) {
  .margin {
    height: 50vh;
  }
  .hero {
    background-position: center;
    padding-top: 40%;
    height: 80vh;
  }

  .hero .row {
    width: 100%;
  }
}
