.courseDetail  {
    height: auto;
    padding: 50px;
    margin-left: 30px;
    margin-right: 30px;
}

.course_container {
  display: grid;
  align-items: center;
  text-align: center;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 20px; /* Gap between columns */
  
}

.courseDetail .content {
  padding: 30px 40px;
}

.image_container {
 /* Take remaining space */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 40px; /* Add padding for spacing */
}

.image_container img {
  width: 100%; /* Ensure image doesn't exceed container width */
  height: auto; /* Maintain aspect ratio */
  
}

.course_container h1 {
  font-size: 45px; /* Increase heading size */
  margin-bottom: 10px; /* Add spacing between heading and paragraph */
  overflow: hidden; /* Hide overflow text */
  text-align: left;
  white-space: normal;
  text-overflow: ellipsis; /* Add ellipsis (...) for overflow text */
}

.course_container p {
  text-align: left;
  padding: 5px;
  white-space: normal; /* Allow text to wrap */
  line-height: 1.5; /* Set line height for better readability */
  /* color: #1eb2a6; */
}

.courseDetail #heading {
  text-align: center;
  margin-top: 50px;
  padding-top: 40px;
}

.courseDetail .item {
  background-color: #1eb2a6;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  color: #fff;
  padding: 25px;
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  transition: 0.5s;
}
/* .courseDetail .item:hover {
  background-color: #1eb2a6;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
} */

.course_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
} 


