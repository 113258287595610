.contacts iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.contacts .left {
  padding: 50px;
}
.contacts .right {
  padding: 50px;
}
.contacts h1 {
  font-size: 30px;
  font-weight: 400;
}
.contacts p {
  color: grey;
}
.contacts .items {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items:self-start;
  text-align: left;
  
}
.contacts .box p {
  font-size: 17px;
  margin-top: 10px;
 
}
.contacts textarea,
.contacts input {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  outline: none;
  border: 1px solid grey;
  border-radius: 10px;
}
form .flexSB input:nth-child(1) {
  margin-right: 10px;
}
.contacts h3 {
  margin-top: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.contacts span {
  color: #1eb2a6;
  font-weight: 600;
  font-size: 15px;
  word-spacing: 5px;
}
.contacts .popup-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1eb2a6;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.popup-message p {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #333;
}
@media screen and (max-width: 768px) {
  .contacts .container {
    flex-direction: column;
  }
  .contacts iframe {
    height: 50vh;
  }
  .contacts .row {
    width: 100%;
  }
}
