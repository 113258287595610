.aboutHome {
  height: auto;
  
}
.aboutHome #heading {
  text-align: center;
  padding-top: 20px;
}

/* .aboutHome .subtitle{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  
  
} */

.aboutHome .left {
  padding: 50px 50px;
}
/* .aboutHome .items {
  margin-top: 50px;
}
.aboutHome .item {
  background-color: #fff;
  margin-top: 30px;
  padding: 20px;
  transition: 0.5s;
} */

/* .aboutHome .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
/* .aboutHome .right {
  padding: 80px 50px;
} */
/* .aboutHome .items {
  margin-top: 20px;
} */
.aboutHome .item {
  background-color: #eeeeee;
  border: 2px solid black;
  border-radius: 10px 10px 10px 10px;
  padding: 20px;
  transition: 0.5s;
}
.aboutHome .img {
  width: 200px;
}
.aboutHome img {
  width: 50px;
  height: 50px;
}
.aboutHome .item h2 {
  font-size: 20px;
  margin-bottom: 15px;
  margin-left: 10px;
}
.aboutHome .item p {
  color: #999999;
  margin-left: 10px;
}
.aboutHome .item:hover {
  background-color: #1eb2a6;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
}
.aboutHome .item:hover p {
  color: #fff;
}
/*----------awrapper------------*/
.awrapper {
  background-image: url("../../../public/images/awrapper.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 30vh;
  color: #fff;
}
.awrapper .box {
  padding: 70px 0;
}
.awrapper img {
  margin-right: 30px;
}
.awrapper h1 {
  font-size: 50px;
}
.awrapper h3 {
  font-size: 20px;
  font-weight: 500;
}
/*----------awrapper------------*/
@media screen and (max-width: 768px) {
  .aboutHome .container {
    flex-direction: column;
    padding-top: 30%;
  }
  .aboutHome .row {
    width: 100%;
  }
  .awrapper {
    height: 50vh;
  }
}
@media screen and (min-width: 768px) and (max-width: 1324px) {
  .aboutHome .container {
    flex-direction: column;
    
  }
  .aboutHome .row {
    width: 100%;
  }
  .awrapper {
    height: 50vh;
  }
}
